import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import moment from 'moment'

const DateInput = ({
  value,
  onChange,
}) => {
  return (
    <div className="date-picker-container" style={{ width: 300, marginTop: '16px', marginLeft: '0px' }}>

      <DatePicker
        format="DD-MM-YYYY"
        onChange={(newValue) =>
          onChange(
            newValue.format("YYYY-MM-DD")
          )
        }
        sx={{
          "& .MuiOutlinedInput-root": {
            width: 300,
            "&:hover, &.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
          },
        }}
        value={
          value
            ? moment(value, "YYYY-MM-DD")
            : null
        }
      />
    </div>
  );
};

export default DateInput;
