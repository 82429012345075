import React from "react";
import { Switch } from "@mui/material";

const TextFieldInput = ({
  value,
  onChange,
}) => {
  return (
    <Switch
      sx={{ marginTop: '16px' }}
      className="text-white"
      onChange={(e) => {
        onChange(e.target.checked);
      }}
      checked={value}
    />
  );
};

export default TextFieldInput;
