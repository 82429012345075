import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { NotificationManager } from "react-notifications";
import { addSavedFilter } from "../../services/requests";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import {
  Button,
} from "@mui/material";


const FilterSaveModal = ({ open, toggle }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [filterName, setFilterName] = useState("");
  const pageInfo = useSelector((state) => state.dashboard.answers.pageInfo);

  const saveFilterHandler = async () => {
    try {
      const token = await getAccessTokenSilently();
      const filters = pageInfo.filters;
      addSavedFilter(token, filters, filterName);
      toggle(false);
      NotificationManager.success("Filter saved successfully!");
    } catch (error) {
      NotificationManager.error("Unable to save the filter!");
    }
  };


  return (
    <Dialog
      open={open}
      onClose={() => {
        toggle(false);
      }}
    >
      <div className="text-center text-xl my-6">Save Current Filter</div>
      <DialogContent>
        <DialogContentText>
          Please enter a name for your filter
        </DialogContentText>
        <TextField
          onInput={(event) => setFilterName(event.target.value)}
          autoFocus
          margin="none"
          color="secondary"
          label="Filter Name"
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            toggle(false);
          }}
          className="text-white"
        >
          Cancel
        </Button>
        <Button onClick={saveFilterHandler} className="text-white">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterSaveModal;
