const twoFiveThousdands = 25000
const twoFiveMillion = 25000000

export const FilterColumns = [
  { column: 'CaseName', type: "in" },
  { column: 'CaseStatus', type: "in" },
  { column: 'SICCode', type: "in" },
  { column: 'WhySuedCategory', type: "in" },
  { column: 'WhySuedAllegations', type: "in" },
  { column: 'FederalCourt', type: "in" },
  { column: 'FederalJudge', type: "in" },
  { column: 'MarketCapHigh', type: "range", step: twoFiveThousdands, extraType: 'range2' },
  { column: 'MarketCapLow', type: "range", step: twoFiveThousdands, extraType: 'range2' },
  { column: 'MarketCapDrop', type: "range", step: twoFiveThousdands, extraType: 'range2' },
  { column: 'PriorYearRevenue', type: "range", step: twoFiveThousdands, extraType: 'range2' },
  { column: 'TotalCash', type: "range", step: twoFiveThousdands, extraType: 'range2' },
  { column: 'CashAmount', type: "range", step: twoFiveMillion },
  { column: 'FloatPct', type: "range", step: 0.25 },
  { column: 'FreeFloat', type: "range", step: twoFiveMillion },
  { column: 'ShortPct', type: "range", step: 0.25 },
  { column: 'InsiderOwnership', type: "range", step: 0.25 },
  { column: 'TentativeSettlementAmount', type: "range", step: twoFiveMillion },
  { column: 'TotalSettlementAmount', type: "range", step: twoFiveMillion },
  { column: 'MarketCap', type: "range", step: twoFiveMillion },
  { column: 'CurrentRatio', type: "range", step: 0.25 },
  { column: 'InstitutionalOwnershipPct', type: "range", step: 0.25 },
  { column: 'PO_YN', type: "eq", valueType: 'boolean' },
  { column: 'IPO_YN', type: "eq", valueType: 'boolean' },
  { column: 'LadderingYN', type: "eq", valueType: 'boolean' },
  { column: 'TransactionalYN', type: "eq", valueType: 'boolean' },
  { column: 'IT_YN', type: "eq", valueType: 'boolean' },
  { column: 'GAAP_YN', type: "eq", valueType: 'boolean' },
  { column: 'RestatedFinancialsYN', type: "eq", valueType: 'boolean' },
  { column: '10B_5_YN', type: "eq", valueType: 'boolean' },
  { column: 'SEC_11_YN', type: "eq", valueType: 'boolean' },
  { column: 'SECActionYN', type: "eq", valueType: 'boolean' },
  { column: 'BankruptcyCaseYN', type: "eq", valueType: 'boolean' },
  { column: 'PostApproval', type: "eq", valueType: 'boolean' },
  { column: 'FederalFilingDate', type: "range", valueType: 'date' },
  { column: 'PriorQuarterDate', type: "range", valueType: 'date' },
  { column: 'FinalSettlementDate', type: "range", valueType: 'date' },
  { column: 'ClassStartDate', type: "range", valueType: 'date' },
  { column: 'ClassEndDate', type: "range", valueType: 'date' },
];

export const defaultFilterValue = FilterColumns.reduce((obj, cur) => {
  const currentColumn = {
    id: cur.column,
    type: cur.type,
    valueType: cur.valueType,
  };

  if (cur.type === 'range') {
    if (cur.valueType === 'date') {

    } else {
      currentColumn.min = 0;
      currentColumn.max = 0;
      currentColumn.options = [0, 100];
    }
  } else if (cur.type === 'in') {
    currentColumn.value = '';
  }

  obj[cur.column] = currentColumn;
  return obj;
}, {})

// {
//   MarketCapHigh: { id: 'MarketCapHigh', min: 0, max: 100, options: [0, 100], type: "range" },
//   MarketCapLow: { id: 'MarketCapLow', min: 0, max: 100, options: [0, 100], type: "range" },
//   MarketCapDrop: { id: 'MarketCapDrop', min: 0, max: 100, options: [0, 100], type: "range" },
//   CashAmount: { id: 'CashAmount', min: 0, max: 100, options: [0, 100], type: "range" },
//   PriorYearRevenue: { id: 'PriorYearRevenue', min: 0, max: 100, options: [0, 100], type: "range" },
//   FloatPct: { id: 'FloatPct', min: 0, max: 100, options: [0, 100], type: "range" },
//   FreeFloat: { id: 'FreeFloat', min: 0, max: 100, options: [0, 100], type: "range" },
//   TotalCash: { id: 'TotalCash', min: 0, max: 100, options: [0, 100], type: "range" },
//   ShortPct: { id: 'ShortPct', min: 0, max: 100, options: [0, 100], type: "range" },
//   InsiderOwnership: { id: 'InsiderOwnership', min: 0, max: 100, options: [0, 100], type: "range" },
//   TentativeSettlementAmount: {
//     id: 'TentativeSettlementAmount',
//     min: 0,
//     max: 100,
//     options: [0, 100],
//     type: "range",
//   },
//   TotalSettlementAmount: { id: 'TotalSettlementAmount', min: 0, max: 100, options: [0, 100], type: "range" },
//   MarketCap: { id: 'MarketCap', min: 0, max: 100, options: [0, 100], type: "range" },
//   InstitutionalOwnershipPct: { id: 'InstitutionalOwnershipPct', min: 0, max: 100, options: [0, 100], type: "range" },
// };