import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import InputComponent from "./InputComponent";
import { tableColumns } from "../../common";

const EditModal = ({
  selectedCase,
  filterOptions,
  column,
  updateHandler,
}) => {
  const [formData, setFormData] = useState(tableColumns.reduce((obj, val) => {
    if (val.inputType) {
      if (!column || column.value === val.value) {
        obj[val.value] = selectedCase[val.value];
      }
    }
    return obj;
  }, {}));

  const handleChange = (key) => (value) => {
    const newFormData = { ...formData, [key]: value };
    if ((key === 'MarketCapHigh' || key === 'MarketCapLow') && newFormData.MarketCapHigh && newFormData.MarketCapLow) {
      newFormData['MarketCapDrop'] = newFormData.MarketCapHigh - newFormData.MarketCapLow;
    }
    setFormData(newFormData);
  };

  return (
    <>
      <div className="text-lg text-center text-white">Edit Case Details</div>
      <div style={{ overflowY: "auto", maxHeight: "60vh", padding: "20px 5px 10px 20px" }}>
        <Grid container spacing={2}>
          {tableColumns.filter(c => c.inputType && (!column || column.value === c.value)).map((c, ind) => <Grid item xs={6} key={ind}><InputComponent column={c} onChange={handleChange(c.value)} value={formData[c.value]} filterOptions={filterOptions[c.value]} disabled={c.disabled}/></Grid>)}
        </Grid>
      </div>
      <Button className="update-btn" onClick={() => updateHandler(formData)}>
        Update
      </Button>
    </>
  );
};

export default EditModal;
