export const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER';
export const SET_SELECTED_COLUMNS = 'SET_SELECTED_COLUMNS';
export const SET_COLUMNS = 'SET_COLUMNS';
export const SET_TABLE_DATA = 'SET_TABLE_DATA';
export const SET_FIRM_DETAIL = 'SET_FIRM_DETAIL';

export const LOAD_ANSWERS_REQUEST = 'LOAD_ANSWERS_REQUEST';
export const LOAD_ANSWERS_SUCCESS = 'LOAD_ANSWERS_SUCCESS';
export const LOAD_ANSWERS_FAIL = 'LOAD_ANSWERS_FAIL';

export const UPDATE_ANSWER_ROW = 'UPDATE_ANSWER_ROW';

export const LOAD_CASE_DETAIL_REQUEST = 'LOAD_CASE_DETAIL_REQUEST';
export const LOAD_CASE_DETAIL_SUCCESS = 'LOAD_CASE_DETAIL_SUCCESS';
export const LOAD_CASE_DETAIL_FAIL = 'LOAD_CASE_DETAIL_FAIL';