import * as types from "../actionTypes";
import * as _ from 'lodash';
import { produce } from "immer";
import { defaultFilterValue, tableColumns } from "../../common";

const intialState = {
  answers: {
    selectedFilter: {
      ...defaultFilterValue,
    },
    loading: false,
    pageInfo: {
      filters: {},
      page: 0,
      pageSize: 100,
      sort: '',
    },
    data: [],
    totalCount: 0,
    selectedColumns: [
      ...tableColumns.map(c => _.pick(c, ['label', 'value', 'isChecked', 'sortable'])),
    ],
  },
};

export default function dashboardReducer(state = { ...intialState }, action) {
  switch (action.type) {
    case types.SET_SELECTED_FILTER:
      return produce(state, (draft) => {
        draft.answers.selectedFilter = action.data;
        draft.answers.pageInfo.filters = action.filterString;
      });
    case types.SET_SELECTED_COLUMNS:
      return produce(state, (draft) => {
        draft.answers.selectedColumns = action.data;
      });
    case types.LOAD_ANSWERS_REQUEST:
      return produce(state, (draft) => {
        draft.answers.loading = true;
      });
    case types.LOAD_ANSWERS_SUCCESS:
      return produce(state, (draft) => {
        draft.answers.data = action.data.data;
        draft.answers.totalCount = action.data.totalCount;
        draft.answers.pageInfo.page = action.data.page;
        draft.answers.pageInfo.pageSize = action.data.pageSize;
        draft.answers.loading = false;
      });

    case types.LOAD_ANSWERS_FAIL:
      return produce(state, (draft) => {
        draft.answers.loading = false;
      });
    case types.UPDATE_ANSWER_ROW:
      return produce(state, (draft) => {
        draft.answers.data = draft.answers.data.map(t => t.Id === action.payload.Id ? ({ ...t, ...action.payload.updateData }) : t);
      });
    default:
      return state;
  }
}
