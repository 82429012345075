import qs from "qs";

export const getFilterQuery = (selectedFilter) => {
  let filters = [];

  if (selectedFilter && Object.keys(selectedFilter).length > 0) {
    // eslint-disable-next-line array-callback-return
    Object.keys(selectedFilter).map((column) => {
      if (selectedFilter[column]["isFilterApply"]) {
        const type = selectedFilter[column]["type"];
        const isHide = selectedFilter[column]["hide"] ? selectedFilter[column]["hide"] : false;

        let filterObj = {};
        if (type === "eq") {
          const value = selectedFilter[column]["value"];
          filterObj = {
            column: column,
            value: value,
            type: "eq",
            hide: isHide,
          };
          filters.push(filterObj);
        } else if (type === "in") {
          const values = selectedFilter[column]["value"];
          if (values && values.length > 0) {
            filterObj = {
              column: column,
              value: values,
              type: "in",
              hide: isHide,
            };
            filters.push(filterObj);
          }
        } else if (type === "range") {
          const valueType = selectedFilter[column]["valueType"];
          if (!valueType) {
            filterObj = {
              column: column,
              min: selectedFilter[column]["min"],
              max: selectedFilter[column]["max"],
              type: "range",
              hide: isHide,
            };
          } else if (valueType === "date") {
            filterObj = {
              column: column,
              min: selectedFilter[column]["min"] ? selectedFilter[column]["min"] : null,
              max: selectedFilter[column]["max"] ? selectedFilter[column]["max"] : null,
              type: "range",
              hide: isHide,
            };
          }
          filters.push(filterObj);
        }
      }
    });

    let flattenedParams = {};
    filters.forEach((filter, index) => {
      Object.entries(filter).forEach(([key, value]) => {
        flattenedParams[`filters[${index}].${key}`] = value;
      });
    });
    const filterString = qs.stringify(flattenedParams, {
      allowDots: true,
      encode: false,
    });

    return filterString;
  }
};

export const getSortQuery = (selectedFilter) => {
  let response = [];

  if (selectedFilter && Object.keys(selectedFilter).length > 0) {
    // eslint-disable-next-line array-callback-return
    Object.keys(selectedFilter).map((column) => {
      if (selectedFilter[column]["sort"]) {
        if (selectedFilter[column]["sort"] === "asc") {
          response.push(column);
        } else {
          response.push(`-${column}`);
        }
      }
    });
  }
  return response.join(",");
};