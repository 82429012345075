import React, { useState } from "react";
import {
  FormControl,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { FinancialColumns, NonFinancialColumns } from '../common';
import MultiSelectComponent from './InputComponent/MultiSelectComponent'

const useStyles = makeStyles((theme) => ({
  smallerRadio: {
    "& .MuiSvgIcon-root": {
      width: "24px",
      height: "24x",
    },
    "& .MuiTypography-root": {
      fontSize: "14px !important",
    },
  },
}));

const UploadCsv = ({uploadcsv , csvLoading }) => {
  const [file, setFile] = useState("");
  const [fileType, setFileType] = useState("financial");
  const [filter, setFilter] = useState({
    options: FinancialColumns,
    value: FinancialColumns
  });

  const classes = useStyles();

  const handleFileInput = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };

  const uploadCsvHandler = () => {
    uploadcsv(file, fileType, filter.value);
  };

  const onChangeHander = (values) => {
    console.log('values', values, filter);
    setFilter({ ...filter, value: values.value })
  }
  return (
    <>
      <div className="text-lg text-center text-white">Upload CSV</div>
      <div className="mt-12">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="text-white text-xl" style={{ marginRight: 24 }}>
              Upload File :{" "}
            </div>
          </Grid>
          <Grid item xs={6}>
            <input
              type="file"
              onChange={handleFileInput}
              accept=".csv"
              className="text-white text-xl"
            />
          </Grid>
        </Grid>
      </div>{" "}
      <div className="mt-12">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="text-white text-xl" style={{ marginRight: 24 }}>
              File Type :{" "}
            </div>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <RadioGroup
                value={fileType}
                onChange={(e) => {
                  setFileType(e.target.value);
                  const columns = e.target.value === 'financial' ? FinancialColumns : NonFinancialColumns;
                  setFilter({ value: columns, options: columns})
                }}
              >
                <FormControlLabel
                  value="legal"
                  control={<Radio className={classes.smallerRadio} />}
                  label="Legal"
                  className="text-white"
                />
                <FormControlLabel
                  value="financial"
                  control={<Radio className={classes.smallerRadio} />}
                  label="Financial"
                  className="text-white"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <div className="text-white text-xl">Columns To Update: <button onClick={function () { onChangeHander({ value: filter.options })}}>All</button> <button onClick={function () { onChangeHander({ value: []})}}>Clear</button></div>
          <Grid item xs={12}>
            <MultiSelectComponent filter={filter} onChange={onChangeHander}/>
          </Grid>
        </Grid>
        <Button className="apply-filter-btn" onClick={uploadCsvHandler}>
          {csvLoading ? <CircularProgress /> : "Upload"}
        </Button>
      </div>
    </>
  );
};

export default UploadCsv;
