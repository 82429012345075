import React from "react";
import TextFieldInput from "./TextFieldInput";
import SwitchInput from './SwitchInput';
import DateInput from './DateInput';
import AutoCompleteInput from './AutoCompleteInput';

const InputComponent = ({ column, ...otherProps }) => {

  return (
    <div>
      <div className="text-xs text-white">
        {column.label}
      </div>
      {column.inputType === 'boolean' && <SwitchInput {...otherProps} />}
      {column.inputType === 'text' && <TextFieldInput inputType={column.inputType} {...otherProps} />}
      {column.inputType === 'number' && <TextFieldInput inputType={column.inputType} {...otherProps} />}
      {column.inputType === 'date' && <DateInput {...otherProps} />}
      {column.inputType === 'autocomplete' && <AutoCompleteInput {...otherProps} />}
    </div>
  );
};

export default InputComponent;
