import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { NotificationManager } from "react-notifications";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { uploadCsv } from "../../services/requests";
import UploadCsv from "../UploadCsv";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "#282828",
  boxShadow: 24,
  p: 4,
  outline: "none !important",
};


const UploadCsvModal = ({ open = false, toggle }) => {
  const [csvLoading, setcsvLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const uploadcsvHandler = async (file, fileType, columns) => {
    try {
      setcsvLoading(true);
      const token = await getAccessTokenSilently();

      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("columns", columns.join(','));
      await uploadCsv(token, formData);
      NotificationManager.success("CSV uploaded successfully!");
      toggle(false);
    } catch (error) {
      setcsvLoading(false);
      NotificationManager.error("Unable to upload csv!");
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => toggle(false)}
    >
      <Box sx={style}>
        <UploadCsv uploadcsv={uploadcsvHandler} csvLoading={csvLoading} />
      </Box>
    </Modal>
  );
};

export default UploadCsvModal;
