import { cellContentRender, getNumberRenderType } from './cellRender';

export const getFilterTags = (currentFilter) => {
  let tags = [];
  let tagContents = [];
  if (currentFilter && Object.keys(currentFilter).length > 0) {
    // eslint-disable-next-line array-callback-return
    Object.keys(currentFilter).map((key) => {
      if (currentFilter[key]["isFilterApply"]) {
        if (currentFilter[key]["type"] === "eq") {
          const value = currentFilter[key]["value"];
          const isHide = currentFilter[key]["hide"];
          tags.push((isHide === "true" || isHide) ? (value ? `${key}: (Hide) Yes` : `${key}: (Hide) No`) : (value ? `${key}: (Show) Yes` : `${key}: (Show) No`));
          tagContents.push({ [key]: value });

        } else if (currentFilter[key]["type"] === "in") {
          const values = currentFilter[key]["value"];
          const length = values.length;
          const isHide = currentFilter[key]["hide"];
          tags.push((isHide === "true" || isHide) ? `${key}: (Hide) ${length} Value(s)` : `${key}: (Show) ${length} Value(s)`);
          tagContents.push({ [key]: values });
        } else if (currentFilter[key]["type"] === "range") {
          const minValue = currentFilter[key]["min"];
          const maxValue = currentFilter[key]["max"];
          const isHide = currentFilter[key]["hide"];
          let minTag
          let maxTag
          if (currentFilter[key].valueType === 'date') {
            minTag = minValue || "";
            maxTag = maxValue || "";
            // tags.push(`${key}: (${(isHide === "true" || isHide) ? 'Hide' : 'Show'}) ${minTag} ~ ${maxTag}`);
          } else {
            minTag = minValue ? cellContentRender(getNumberRenderType(currentFilter[key].step))(minValue) : "";
            maxTag = maxValue ? cellContentRender(getNumberRenderType(currentFilter[key].step))(maxValue) : "";
          }
          tags.push(`${key}: (${(isHide === "true" || isHide) ? 'Hide' : 'Show'}) ${minTag} ~ ${maxTag}`);
          tagContents.push({ [key]: [minValue, maxValue] });
      }
      }
    });
  }
  return { tags, tagContents };
};