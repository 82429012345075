import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";

const DateRangeComponent = ({ filter, onChange }) => {
  const handleDateMinChange = (value) => {
    const minValue = value ? value.format("YYYY-MM-DD") : value;
    onChange({ min: minValue }, !!minValue || !!filter.max);
  };

  const handleDateMaxChange = (value) => {
    const maxValue = value ? value.format("YYYY-MM-DD") : value;
    onChange({ max: maxValue }, !!maxValue || !!filter.min);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div className="date-picker-container">
        <DatePicker
          format="MM-DD-YYYY"
          IconComponent={CalendarMonthIcon}
          onChange={(newValue) =>
            handleDateMinChange(newValue)
          }
          slotProps={{
            field: { clearable: true },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&:hover, &.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              },
            },
          }}
          value={
            filter.isFilterApply
              ? filter.min &&
              moment(
                filter.min,
                "YYYY-MM-DD"
              )
              : null
          }
        />
      </div>
      <div className="date-picker-container">
        <DatePicker
          format="MM-DD-YYYY"
          onChange={(newValue) =>
            handleDateMaxChange(newValue)
          }
          slotProps={{
            field: { clearable: true },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&:hover, &.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              },
            },
          }}
          value={
            filter.isFilterApply
              ? filter.max &&
              moment(
                filter.max,
                "YYYY-MM-DD"
              )
              : null
          }
        />
      </div>
    </div>
  );
};

export default DateRangeComponent;
