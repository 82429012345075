import React from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const SwitchComponent = ({ filter, onChange, handleClear }) => {

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <FormControl style={{ width: '100%', border: "1px solid white", borderRadius: 10, color: "white" }}>
        <Select
          value={filter['value']}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          onChange={(e) => { onChange({ value: e.target.value }, e.target.value !== ''); }}
        >
          <MenuItem value={''}>All</MenuItem>
          <MenuItem value={1}>Yes</MenuItem>
          <MenuItem value={0}>No</MenuItem>
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

export default SwitchComponent;
