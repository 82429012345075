import React from "react";
import MultiSelectComponent from "./MultiSelectComponent";
import RangeInputComponent from "./RangeInputComponent";
import RangeInput2Component from "./RangeInput2Component";
import DateRangeComponent from './DateRangeComponent';
import SwitchCoponent from './SwitchComponent';
import CloseIcon from "@mui/icons-material/Close";

const InputComponent = ({ filterKey, filter, onChange, checkHide }) => {
  const handleClear = () => {
    const initialValue = {};
    if (filter.type === 'range' && !filter.valueType) {
      initialValue.min = filter.options.min;
      initialValue.max = filter.options.max;
    } else if (filter.type === 'in') {
      initialValue.value = [];
    } else if (filter.type === 'eq' && filter.valueType === 'boolean') {
      initialValue.value = '';
    } else if (filter.type === 'range' && filter.valueType === 'date') {
      initialValue.min = null;
      initialValue.max = null;
    }
    onChange(initialValue, false);
  }
  return (
    <div>
      <div className="text-xs text-white" style={{ marginBottom: 6, display: "flex", alignItems: "center" }}>
        {filterKey}
        {filter.isFilterApply &&
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="cursor-pointer" onClick={handleClear}><CloseIcon className="close-icon" /></span>
            <span style={{ marginLeft: 6 }}>Hide</span>
            <input className="cursor-pointer" type="checkbox" style={{ marginTop: 3 }} onChange={(e) => { checkHide({ [filterKey]: e.target.checked }) }} checked={filter.hide ? filter.hide : false} />
          </div>}
      </div>
      <div>
        {filter.type === 'in' && <MultiSelectComponent filter={filter} onChange={onChange} />}
        {filter.type === 'range' && !filter.valueType && !filter.extraType && <RangeInputComponent filter={filter} onChange={onChange} />}
        {filter.type === 'range' && !filter.valueType && filter.extraType === 'range2' && <RangeInput2Component filter={filter} onChange={onChange} />}
        {filter.type === 'range' && filter.valueType === 'date' && <DateRangeComponent filter={filter} onChange={onChange} />}
        {filter.type === 'eq' && filter.valueType === 'boolean' && <SwitchCoponent filter={filter} onChange={onChange} handleClear={handleClear} />}
      </div>
    </div >
  );
};

export default InputComponent;
