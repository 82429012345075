
import { cellContentRender} from '../util';

export const tableColumns = [
  {
    label: "Case ID",
    value: "CaseID",
    isChecked: true,
    sortable: true,
  },
  {
    label: "Case Name",
    value: "CaseName",
    isChecked: true,
    inputType: 'text',
    sortable: true,
  },
  {
    label: "Case Status",
    value: "CaseStatus",
    isChecked: true,
    inputType: 'autocomplete',
    sortable: true,
  },
  // company name
  {
    label: "Ticker",
    value: "Ticker",
    isChecked: true,
    inputType: 'text',
  },
  {
    label: "SIC Code",
    value: "SICCode",
    isChecked: true,
    inputType: 'text',
    sortable: true,
  },
  {
    label: "Federal Filing Date",
    value: "FederalFilingDate",
    isChecked: true,
    cellContentRender: cellContentRender('date'),
    inputType: 'date',
    sortable: true,
  },
  {
    label: "Why Sued Category",
    value: "WhySuedCategory",
    isChecked: true,
    inputType: 'text',
    sortable: true,
  },
  {
    label: "Why Sued Allegations",
    value: "WhySuedAllegations",
    isChecked: true,
    inputType: 'text',
    sortable: true,
  },
  {
    label: "Market Cap High",
    value: "MarketCapHigh",
    isChecked: true,
    cellContentRender: cellContentRender('currency'),
    inputType: 'number',
    sortable: true,
  },
  {
    label: "Market Cap Low",
    value: "MarketCapLow",
    isChecked: true,
    cellContentRender: cellContentRender('currency'),
    inputType: 'number',
    sortable: true,
  },
  {
    label: "Market Cap Drop",
    value: "MarketCapDrop",
    isChecked: true,
    cellContentRender: cellContentRender('currency'),
    inputType: 'number',
    disabled: true,
    sortable: true,
  },
  {
    label: "Settlement Cash Amount",
    value: "CashAmount",
    isChecked: true,
    cellContentRender: cellContentRender('currency'),
    inputType: 'number',
    sortable: true,
  },
  {
    label: "Prior Year Revenue",
    value: "PriorYearRevenue",
    isChecked: true,
    cellContentRender: cellContentRender('currency'),
    inputType: 'number',
    sortable: true,
  },
  {
    label: "Free Float (%)",
    value: "FloatPct",
    isChecked: true,
    cellContentRender: cellContentRender('percent'),
    inputType: 'number',
    sortable: true,
  },
  {
    label: "Free Float",
    value: "FreeFloat",
    isChecked: true,
    cellContentRender: cellContentRender('currency'),
    inputType: 'number',
    sortable: true,
  },
  {
    label: "Federal Court",
    value: "FederalCourt",
    isChecked: true,
    inputType: 'text',
  },
  {
    label: "Federal Judge",
    value: "FederalJudge",
    isChecked: true,
    inputType: 'text',
  },
  {
    label: "Federal Case Number",
    value: "FederalCaseNumber",
    isChecked: true,
    inputType: 'text',
  },
  {
    label: "Prior Quarter Date",
    value: "PriorQuarterDate",
    isChecked: true,
    cellContentRender: cellContentRender('date'),
    inputType: 'date',
    sortable: true,
  },
  {
    label: "Total Non Cash Amount",
    value: "TotalNonCashAmount",
    isChecked: true,
    inputType: 'text',
  },
  {
    label: "Total Cash",
    value: "TotalCash",
    isChecked: true,
    cellContentRender: cellContentRender('currency'),
    inputType: 'number',
    sortable: true,
  },
  {
    label: "Institutional Ownership Prior Quarter",
    value: "InstitutionalOwnershipPriorQuarter",
    isChecked: true,
    cellContentRender: cellContentRender('percent'),
    inputType: 'number',
    sortable: true,
  },
  {
    label: "Post Approval",
    value: "PostApproval",
    isChecked: true,
    inputType: 'boolean',
    cellContentRender: cellContentRender('boolean'),
  },
  {
    label: "Cash Share",
    value: "CashShare",
    isChecked: true,
    cellContentRender: cellContentRender('currency-small'),
    inputType: 'number',
    sortable: true,
  },
  {
    label: "Final Settlement Date",
    value: "FinalSettlementDate",
    isChecked: true,
    cellContentRender: cellContentRender('date'),
    inputType: 'date',
    sortable: true,
  },
  {
    label: "Short(%)",
    value: "ShortPct",
    isChecked: true,
    cellContentRender: cellContentRender('percent'),
    inputType: 'number',
    sortable: true,
  },
  {
    label: "Insider Ownership",
    value: "InsiderOwnership",
    isChecked: true,
    inputType: 'number',
    cellContentRender: cellContentRender('percent'),
    sortable: true,
  },
  {
    label: "Current Ratio",
    value: "CurrentRatio",
    isChecked: true,
    inputType: 'number',
    sortable: true,
  },
  {
    label: "Class Start Date",
    value: "ClassStartDate",
    isChecked: true,
    cellContentRender: cellContentRender('date'),
    inputType: 'date',
    sortable: true,
  },
  {
    label: "Class End Date",
    value: "ClassEndDate",
    isChecked: true,
    cellContentRender: cellContentRender('date'),
    inputType: 'date',
    sortable: true,
  },
  {
    label: "Day of the Week",
    value: "ClassEndDate",
    isChecked: true,
    cellContentRender: cellContentRender('dayofweek'),
  },
  {
    label: "Total Settlement Amount",
    value: "TotalSettlementAmount",
    isChecked: true,
    cellContentRender: cellContentRender('currency'),
    inputType: 'number',
    sortable: true,
  },
  {
    label: "Tentative Settlement Amount",
    value: "TentativeSettlementAmount",
    isChecked: true,
    cellContentRender: cellContentRender('currency'),
    inputType: 'number',
    sortable: true,
  },
  {
    label: "Institutional Ownership Pct",
    value: "InstitutionalOwnershipPct",
    isChecked: true,
    cellContentRender: cellContentRender('percent'),
    inputType: 'number',
    sortable: true,
  },
  {
    label: "Market Cap",
    value: "MarketCap",
    isChecked: true,
    cellContentRender: cellContentRender('currency'),
    inputType: 'number',
    sortable: true,
  },
  {
    label: "PO",
    value: "PO_YN",
    isChecked: true,
    cellContentRender: cellContentRender('boolean'),
    inputType: 'boolean',
  },
  {
    label: "IPO",
    value: "IPO_YN",
    isChecked: true,
    cellContentRender: cellContentRender('boolean'),
    inputType: 'boolean',
  },
  {
    label: "Laddering",
    value: "LadderingYN",
    isChecked: true,
    cellContentRender: cellContentRender('boolean'),
    inputType: 'boolean',
  },
  {
    label: "Transactional",
    value: "TransactionalYN",
    isChecked: true,
    cellContentRender: cellContentRender('boolean'),
    inputType: 'boolean',
  },
  {
    label: "IT",
    value: "IT_YN",
    isChecked: true,
    cellContentRender: cellContentRender('boolean'),
    inputType: 'boolean',
  },
  {
    label: "GAAP",
    value: "GAAP_YN",
    isChecked: true,
    cellContentRender: cellContentRender('boolean'),
    inputType: 'boolean',
  },
  {
    label: "Restated Financials",
    value: "RestatedFinancialsYN",
    isChecked: true,
    cellContentRender: cellContentRender('boolean'),
    inputType: 'boolean',
  },
  {
    label: "10B51",
    value: "10B_5_YN",
    isChecked: true,
    cellContentRender: cellContentRender('boolean'),
    inputType: 'boolean',
  },
  {
    label: "SEC 11",
    value: "SEC_11_YN",
    isChecked: true,
    cellContentRender: cellContentRender('boolean'),
    inputType: 'boolean',
  },
  {
    label: "SEC Action",
    value: "SECActionYN",
    isChecked: true,
    cellContentRender: cellContentRender('boolean'),
    inputType: 'boolean',
  },
  {
    label: "Bankruptcy Case",
    value: "BankruptcyCaseYN",
    isChecked: true,
    cellContentRender: cellContentRender('boolean'),
    inputType: 'boolean',
  },
];

