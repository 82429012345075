import { TableHead } from "@mui/material";

export const TableStickyHead = ({ children }) => (
  <TableHead
    style={{
      position: 'sticky',
      top: 0,
      zIndex: 1,
    }}
  >
    {children}
  </TableHead>
);
